import { ReactNode } from 'react';
import {
  EventHit,
  RenderCard,
  RenderWrapper,
} from '../algolia/catalog-wrappers/EventsCalendar';

export type TransformToEventProps<THit extends EventHit<Date>> = {
  hit: THit;
  Card: RenderCard<EventHit<Date>, Date>;
  Wrapper?: RenderWrapper<EventHit<Date>, Date>;
};

export const transformToEventKeyed = <THit extends EventHit<Date>>({
  hit,
  Card,
  Wrapper,
}: TransformToEventProps<THit>) => {
  // eslint-disable-next-line @blumintinc/blumint/extract-global-constants
  const cardRendered: ReactNode = <Card {...hit} />;

  return {
    key: hit.objectID,
    Node: Wrapper ? <Wrapper hit={hit}>{cardRendered}</Wrapper> : cardRendered,
  };
};

export const transformToEvent = <THit extends EventHit<Date>>({
  hit,
  Card,
  Wrapper,
}: TransformToEventProps<THit>) => {
  const { key, Node } = transformToEventKeyed({
    hit,
    Card,
    Wrapper,
  });

  return {
    key,
    Node,
    dateDay: hit.dateDay,
  };
};
