import { toGroupDenormalizationPath } from '../../../functions/src/types/firestore/User/GroupDenormalization/path';

export type FetchIsInGroupDenormalizedParams = {
  userId: string;
  groupId: string;
};

export const fetchIsInGroupDenormalized = async ({
  userId,
  groupId,
}: FetchIsInGroupDenormalizedParams) => {
  const { firestore } = await import('../../config/firebase-client/firestore');
  const { doc, getDoc } = await import('firebase/firestore');

  const docRef = doc(
    firestore,
    toGroupDenormalizationPath({ userId, groupId }),
  );
  const docSnap = await getDoc(docRef);
  return docSnap.exists();
};
