// eslint-disable-next-line @blumintinc/blumint/require-dynamic-firebase-imports
import type { Timestamp } from 'firebase-admin/firestore';
import { format } from 'date-fns';
import { convertPresentToFuture } from '../string/convertPresentToFuture';
import { convertPresentToPast } from '../string/convertPresentToPast';
import { RequireOnlyOne } from '../../types/utility-types';
import { offsetUtc } from '../time/offsetUtc';
import { RelativeTimeFormatter } from '../date/RelativeTimeFormatter';
import { IrregularVerbKey } from '../notifications/util/verbs';
import { fromTimestamp, isTimestamp } from '../firestore/timestamp';
import { MONTH_DAY_FORMAT, TIME_FORMAT } from './formats';

export type FormatDateTimeOptions = {
  dateFormat?: Intl.DateTimeFormatOptions | false;
  timeFormat?: Intl.DateTimeFormatOptions | false;
  separator?: string;
};

export type ToDateStringSettings = Partial<
  RequireOnlyOne<{
    locale: string;
    utcOffsetHours: number;
  }>
>;

export function toDateString(
  date: Date,
  { locale, utcOffsetHours: utcOffset }: ToDateStringSettings = {},
) {
  if (locale) {
    const formatter = new Intl.DateTimeFormat(locale, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });

    const parts = formatter.formatToParts(date);

    const dateParts = parts.reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {} as { [key: string]: string });

    return `${dateParts.year}-${dateParts.month}-${dateParts.day}`;
  }
  const dateOffset = utcOffset ? offsetUtc(date, utcOffset) : date;
  const year = dateOffset.getFullYear();
  const month = dateOffset.getMonth() + 1;
  const day = dateOffset.getDate();

  const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day
    .toString()
    .padStart(2, '0')}`;

  return formattedDate;
}

export const shiftDate = (date: Date, offsetDays: number) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + offsetDays);
  return newDate;
};

export const ONE_SECOND_MS = 1000;
const UNITS_COUNT = 2 as const;

export function formatTimeWithVerb<TTime extends Timestamp | Date>(
  timestamp: TTime,
  verb: IrregularVerbKey,
) {
  const date = isTimestamp(timestamp)
    ? fromTimestamp(timestamp, (value) => {
        return value;
      })
    : (timestamp as Date);
  const { relative, absolute, isPast } = formatTimeAndRelative(date);
  const verbTense = isPast
    ? convertPresentToPast(verb)
    : convertPresentToFuture(verb);
  return `${verbTense} ${relative} on ${absolute}`;
}

export function formatTimeAndRelative(timestamp: Date) {
  const now = new Date();
  const relativeFormatter = new RelativeTimeFormatter({
    unitsCount: UNITS_COUNT,
  });
  const relative = relativeFormatter.format(timestamp, now);
  const absoluteDate = format(timestamp, MONTH_DAY_FORMAT);
  const absoluteTime = format(timestamp, TIME_FORMAT);
  const absolute = `${absoluteDate} at ${absoluteTime}`;
  const isPast = timestamp.getTime() < now.getTime();

  return { relative, absolute, isPast };
}
