import { FC, useCallback, useMemo, useState } from 'react';
import Stack from '@mui/material/Stack';
import { memo } from '../../../util/memo';
import { useCreateEventContext } from '../../../contexts/organizer/create-event/CreateEventContext';
import { useWizard } from '../../wizard/Wizard';
import { SelectableMethodOption } from '../SelectableMethodOption';
import { EventFlowBase } from './EventFlowBase';
import { CREATION_METHODS } from 'src/contexts/organizer/create-event/constants';

export const SELECT_CREATION_METHOD_TITLE = 'Select Starting Point' as const;

export const SelectCreationMethod: FC = memo(
  function SelectCreationMethodUnmemoized() {
    const { go } = useWizard();
    const {
      eventCreationSettings: { eventType },
      dispatchEventCreationSettings,
    } = useCreateEventContext();

    const [methodSelected, setMethodSelected] = useState<string>();

    const subtitle = useMemo(() => {
      const eventTypeLower = eventType.toLowerCase();
      return `Create a new ${eventTypeLower} from scratch, or copy a previous ${eventTypeLower} and go from there.`;
    }, [eventType]);

    const selectOption = useCallback(
      (value: string) => {
        dispatchEventCreationSettings({
          type: 'SET_METHOD',
          payload: value,
        });
        setMethodSelected(value);
        const nextPage = value === 'New' ? 'Select Game' : 'Copy Event';

        go(nextPage);
      },
      [dispatchEventCreationSettings, go],
    );

    const Options = useMemo(() => {
      return (
        <Stack spacing={2}>
          {CREATION_METHODS.map((value) => {
            return (
              <SelectableMethodOption
                key={value}
                isSelected={value === methodSelected}
                value={value}
                // eslint-disable-next-line @blumintinc/blumint/enforce-callback-memo
                onSelect={() => {
                  return selectOption(value);
                }}
              />
            );
          })}
        </Stack>
      );
    }, [methodSelected, selectOption]);

    return (
      <EventFlowBase
        Content={Options}
        subtitle={subtitle}
        title={SELECT_CREATION_METHOD_TITLE}
      />
    );
  },
);
