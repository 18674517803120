import Box from '@mui/material/Box';
import { useMemo } from 'react';
import { memo } from '../../../util/memo';
import { ImageOptimized } from '../../image/ImageOptimized';
import { ViewerCount } from '../../livestream/ViewerCount';
import { ContentOverlay } from '../../ContentOverlay';

const SX = { borderRadius: '8px' } as const;

export type TournamentCardThumbnailProps = {
  imgUrl: string;
  width: number;
  height: number;
  primaryPlaybackId?: string;
};

const TournamentCardThumbnailUnmemoized = ({
  imgUrl,
  width,
  height,
  primaryPlaybackId,
}: TournamentCardThumbnailProps) => {
  const viewerCount = useMemo(() => {
    return (
      !!primaryPlaybackId && <ViewerCount playbackId={primaryPlaybackId} />
    );
  }, [primaryPlaybackId]);

  return (
    <Box position="relative">
      <ImageOptimized height={height} src={imgUrl} style={SX} width={width} />
      <ContentOverlay sx={{ position: 'absolute', top: 2, left: 2, px: 1 }}>
        {viewerCount}
      </ContentOverlay>
    </Box>
  );
};

export const TournamentCardThumbnail = memo(TournamentCardThumbnailUnmemoized);
