import { FC, useCallback, useMemo, useState } from 'react';
import { UseConfigureProps } from 'react-instantsearch';
import {
  useDeepCompareCallback,
  useDeepCompareMemo,
} from '@blumintinc/use-deep-compare';
import { memo } from '../../util/memo';
import { dateOnlyMillis } from '../../../functions/src/util/algolia/eventsTransform';
// eslint-disable-next-line @blumintinc/blumint/enforce-css-media-queries
import { useMobile } from '../../hooks/useMobile';
import { useUtcOffset } from '../../hooks/useUtcOffset';
import { UTC_ALGOLIA_INDEX_NAME_MAP } from '../../config/algolia/algoliaIndexMap';
import { CalendarDaily, CalendarDailyProps } from './catalogs/CalendarDaily';
import {
  AlgoliaLayoutBidirectional,
  AlgoliaLayoutBidirectionalProps,
} from './AlgoliaLayoutBidirectional';
import {
  EventsCalendar,
  EventsCalendarProps,
} from './catalog-wrappers/EventsCalendar';
import { assertSafe } from 'functions/src/util/assertSafe';

export const DISTINCT_VALUE_CALENDAR = 4 as const;

export type AlgoliaEventsCalendarProps = Omit<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  EventsCalendarProps<any>,
  'hits' | 'onLoadMore' | 'CalendarDaily' | 'Extension'
> &
  Pick<
    AlgoliaLayoutBidirectionalProps,
    'transformHits' | 'transformHitsExtension'
  > & {
    initialDate?: Date;
    configureOptions?: UseConfigureProps;
    height?: string;
  };

export const MOBILE_PAGES = 4 as const;
export const DESKTOP_PAGES = 6 as const;

const AlgoliaEventsCalendarUnmemoized = ({
  initialDate,
  configureOptions,
  transformHits,
  transformHitsExtension,
  height = '100%',
  Card,
  Wrapper,
  SearchRight,
  ...calendarProps
}: AlgoliaEventsCalendarProps) => {
  const [pivotDate, setPivotDate] = useState<Date>(initialDate || new Date());
  const pivotTime = pivotDate.getTime();
  const utcOffset = useUtcOffset();

  // eslint-disable-next-line @blumintinc/blumint/enforce-css-media-queries
  const isMobile = useMobile();

  const defaultPages = useMemo(() => {
    return isMobile ? MOBILE_PAGES : DESKTOP_PAGES;
  }, [isMobile]);

  const commonConfigureOptions = useDeepCompareMemo(() => {
    const configureOptionsDefaulted = configureOptions || {};
    return {
      ...configureOptionsDefaulted,
      hitsPerPage: configureOptionsDefaulted.hitsPerPage || defaultPages,
      numericFilters: [...(configureOptionsDefaulted.numericFilters || [])],
    };
  }, [configureOptions, defaultPages]);

  const RenderCalendarDaily = useCallback<
    FC<
      Pick<
        CalendarDailyProps,
        'dayToEvents' | 'onCalendarEndReached' | 'Extension' | 'query'
      >
    >
  >(
    (props) => {
      return (
        <CalendarDaily
          {...props}
          height={height}
          initialDate={new Date(pivotTime)}
          onDateSelect={setPivotDate}
        />
      );
    },
    [height, pivotTime],
  );

  const RenderCatalogWrapper = useDeepCompareCallback<
    AlgoliaLayoutBidirectionalProps['CatalogWrapperBidirectional']
  >(
    ({ hits, onLoadMore, Extension, query }) => {
      return (
        <EventsCalendar
          {...calendarProps}
          CalendarDaily={RenderCalendarDaily}
          Card={Card}
          Extension={Extension}
          hits={hits}
          query={query}
          SearchRight={SearchRight}
          Wrapper={Wrapper}
          onLoadMore={onLoadMore}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Card, RenderCalendarDaily, SearchRight, Wrapper, calendarProps],
  );

  const configureOptionsWithPivotTime = useMemo(() => {
    const eventIndex =
      UTC_ALGOLIA_INDEX_NAME_MAP[assertSafe(Number(utcOffset))];
    const baseIndexName = eventIndex.replace(/_asc$/, '');

    const ascIndexName = `${baseIndexName}_asc`;
    const descIndexName = `${baseIndexName}_desc`;

    return {
      [assertSafe(ascIndexName)]: {
        ...commonConfigureOptions,
        numericFilters: [
          ...commonConfigureOptions.numericFilters,
          `dateDay >= ${dateOnlyMillis(new Date(pivotTime))}`,
        ],
        distinct: DISTINCT_VALUE_CALENDAR,
      },
      [assertSafe(descIndexName)]: {
        ...commonConfigureOptions,
        numericFilters: [
          ...commonConfigureOptions.numericFilters,
          `dateDay < ${dateOnlyMillis(new Date(pivotTime))}`,
        ],
        distinct: DISTINCT_VALUE_CALENDAR,
      },
    } as const;
  }, [commonConfigureOptions, pivotTime, utcOffset]);

  return (
    <AlgoliaLayoutBidirectional
      Card={Card}
      CatalogWrapperBidirectional={RenderCatalogWrapper}
      configureOptions={configureOptionsWithPivotTime}
      transformHits={transformHits}
      transformHitsExtension={transformHitsExtension}
      Wrapper={Wrapper}
    />
  );
};

export const AlgoliaEventsCalendar = memo(AlgoliaEventsCalendarUnmemoized);
