import { useEffect, useState, useCallback } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { fetchIsInGroupDenormalized } from '../../util/group/fetchIsInGroupDenormalized';

export const useIsUserInGroup = (groupId?: string) => {
  const [isInGroup, setIsInGroup] = useState<boolean | 'unknown'>('unknown');
  const { userData } = useAuth();

  const checkIsUserInGroup = useCallback(
    async (groupIdSpecific: string) => {
      if (!userData?.id || !userData?.groups?.length) {
        return false;
      }

      const isInLatest1000 = userData?.groups?.includes(groupIdSpecific);

      if (!isInLatest1000 && userData.groups.length >= 1000) {
        return await fetchIsInGroupDenormalized(userData.id, groupIdSpecific);
      }

      return isInLatest1000;
    },
    [userData?.id, userData?.groups],
  );

  useEffect(() => {
    const assertStatus = async () => {
      if (!groupId) {
        return;
      }
      const result = await checkIsUserInGroup(groupId);
      setIsInGroup(result);
    };

    assertStatus();
  }, [userData?.id, groupId, userData?.groups, checkIsUserInGroup]);

  return { isInGroup, checkIsUserInGroup };
};
