export const EVENT_PATH = `Game/{gameId}/{eventType}/{eventId}` as const;
export type EventPath = typeof EVENT_PATH;

export type ToEventPathProps = {
  gameId: string;
  eventType: string;
  eventId: string;
};
export const toEventPath = ({
  gameId,
  eventType,
  eventId,
}: ToEventPathProps) => {
  return `${EVENT_PATH.replace('{gameId}', gameId)
    .replace('{eventType}', eventType)
    .replace('{eventId}', eventId)}`;
};
