import { useMemo, useCallback, ChangeEvent } from 'react';
import { memo } from '../../../util/memo';
import {
  TextFieldDropdown,
  TextFieldDropdownProps,
} from '../../TextFieldDropdown';
import { useDebouncedSearch } from '../../../hooks/algolia/useDebouncedSearch';
import { NoSearchResults } from '../search/NoSearchResults';
import {
  VerticalCarousel,
  VerticalCarouselProps,
} from './generic/VerticalCarousel';

export type SearchDropdownProps = Pick<
  VerticalCarouselProps,
  'content' | 'onNearEnd'
> &
  Omit<TextFieldDropdownProps, 'PopoverContent'>;

const SearchDropdownUnmemoized: React.FC<SearchDropdownProps> = ({
  content,
  onNearEnd,
  onChange,
  ...props
}) => {
  const { applyQueryChange } = useDebouncedSearch();

  const contentCarousel = useMemo(() => {
    if (content.length === 0) {
      return <NoSearchResults />;
    }
    return <VerticalCarousel content={content} onNearEnd={onNearEnd} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, onNearEnd]);

  const changeQuery = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e);
      applyQueryChange(e);
    },
    [onChange, applyQueryChange],
  );

  return (
    <TextFieldDropdown
      PopoverContent={contentCarousel}
      {...props}
      onChange={changeQuery}
    />
  );
};

export const SearchDropdown = memo(SearchDropdownUnmemoized);
