import { memo } from '../../util/memo';
import { isSelectablePathProps } from '../../util/routing/isSelectablePathProps';
import { SelectablePath, SelectablePathProps } from './SelectablePath';
import { SelectableState, SelectableStateProps } from './SelectableState';

export const DEFAULT_SEGMENT_NAME = 'selected' as const;

export type SelectableRoutedProps = SelectableStateProps | SelectablePathProps;

const SelectableRoutedUnmemoized = (props: SelectableRoutedProps) => {
  if (isSelectablePathProps(props)) {
    return <SelectablePath {...props} />;
  }
  return <SelectableState {...props} />;
};

export const SelectableRouted = memo(SelectableRoutedUnmemoized);
