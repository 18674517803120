import { useCallback, useMemo } from 'react';
import {
  DialogActionsStandard,
  ActionButtonProps,
} from '../../dialog/DialogActionsStandard';
import { useWizard } from '../../wizard/Wizard';
import { useAuth } from '../../../contexts/AuthContext';
import { EventFlowBase } from './EventFlowBase';

export function DeleteTournamentSuccess() {
  const { uid } = useAuth();
  const profileHref = useMemo(() => {
    if (!uid) {
      return;
    }
    return `/${uid}`;
  }, [uid]);

  const { go } = useWizard();

  const createNew = useCallback(() => {
    return go('Select Starting Point');
  }, [go]);

  const close = useCallback(() => {
    go(undefined);
  }, [go]);

  const buttons: ActionButtonProps[] = useMemo(() => {
    return [
      {
        isAsync: false,
        onClick: createNew,
        children: 'Create Another',
      },
      {
        isAsync: false,
        href: profileHref,
        onClick: close,
        children: 'Back to Profile',
      },
    ];
  }, [createNew, close, profileHref]);

  return (
    <EventFlowBase
      Content={<DialogActionsStandard buttons={buttons} />}
      title="Tournament deleted"
    />
  );
}
