export const GROUP_DENORMALIZATION_COLLECTION = 'GroupDenormalization' as const;
export const GROUP_DENORMALIZATION_PATH =
  `/User/{userId}/${GROUP_DENORMALIZATION_COLLECTION}/{groupId}` as const;

export type GroupDenormalizationPath = typeof GROUP_DENORMALIZATION_PATH;

export type GroupDenormalizationPathParams = {
  userId: string;
  groupId: string;
};

export const toGroupDenormalizationPath = ({
  userId,
  groupId,
}: GroupDenormalizationPathParams) => {
  return GROUP_DENORMALIZATION_PATH.replace('{userId}', userId).replace(
    '{groupId}',
    groupId,
  );
};

export const toGroupDenormalizationCollectionPath = (userId: string) => {
  return `User/${userId}/${GROUP_DENORMALIZATION_COLLECTION}`;
};
