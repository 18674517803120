import {
  Context,
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useReducer,
  useState,
  useMemo,
} from 'react';
import { memo } from '../../../util/memo';
import { Tournament } from '../../../../functions/src/types/firestore/Game/Tournament';
import { EditableStepsProvider } from '../EditableStepsContext';
import { HttpsError } from '../../../../functions/src/util/errors/HttpsError';
import { toEventPath } from '../../../../functions/src/types/firestore/Game/Event/path';
import { assertSafe } from '../../../../functions/src/util/assertSafe';
import {
  EventCreationSettingsAction,
  eventCreationSettingsReducer,
} from './reducer';
import {
  EventCreationProps,
  EVENT_CREATION_SETTINGS_DEFAULT,
  EVENT_TYPE_TO_EDITABLE_FIELDS,
} from './constants';

//TODO: | Giveaway
export type EventDocument<T = Date> = Tournament<T>;
export type CreateEventContextType<T extends EventDocument> = {
  event?: T;
  newGameId?: string;
  setNewGameId: Dispatch<SetStateAction<string | undefined>>;
  setEvent: Dispatch<SetStateAction<T | undefined>>;
  eventCreationSettings: EventCreationProps;
  dispatchEventCreationSettings: Dispatch<EventCreationSettingsAction>;
};

export type CreateEventProviderProps<T extends EventDocument> = {
  children: ReactNode;
  event: T;
  eventCreationSettings: EventCreationProps;
};

/** this 'any' is overwritten when the hook is used with a generic */

const CreateEventContext = createContext<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  CreateEventContextType<any> | undefined
>(undefined);

export const CreateEventProvider = memo(
  <T extends EventDocument>({
    children,
    event: eventInitial,
    eventCreationSettings:
      eventCreationSettingsInitial = EVENT_CREATION_SETTINGS_DEFAULT,
  }: CreateEventProviderProps<T>) => {
    const [event, setEvent] = useState<T | undefined>(eventInitial);
    const [newGameId, setNewGameId] = useState<string>();
    const [eventCreationSettings, dispatch] = useReducer(
      eventCreationSettingsReducer,
      eventCreationSettingsInitial,
    );

    const eventDocPath = useMemo(() => {
      if (!event) {
        return;
      }
      const { gameId, id } = event;

      return toEventPath({
        gameId,
        eventType: eventCreationSettings.eventType,
        eventId: id,
      });
    }, [event, eventCreationSettings]);

    return (
      <CreateEventContext.Provider
        value={{
          event,
          setEvent,
          newGameId,
          setNewGameId,
          eventCreationSettings,
          dispatchEventCreationSettings: dispatch,
        }}
      >
        <EditableStepsProvider
          documentInitial={eventInitial}
          documentPath={eventDocPath}
          editableFields={
            EVENT_TYPE_TO_EDITABLE_FIELDS[
              assertSafe(eventCreationSettings.eventType)
            ]
          }
        >
          {children}
        </EditableStepsProvider>
      </CreateEventContext.Provider>
    );
  },
);

export function useCreateEventContext<T extends EventDocument>() {
  const context = useContext<CreateEventContextType<T>>(
    // eslint-disable-next-line @blumintinc/blumint/no-type-assertion-returns
    CreateEventContext as unknown as Context<CreateEventContextType<T>>,
  );
  if (!context) {
    throw new HttpsError(
      'failed-precondition',
      'useCreateEventContext must be used within a CreateEventProvider',
    );
  }
  return context;
}
