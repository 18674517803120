/* eslint-disable react/display-name */
import { ComponentProps, useCallback, useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import { memo } from '../../../util/memo';
import {
  VerticalCarousel,
  VerticalCarouselProps,
} from '../catalogs/generic/VerticalCarousel';
import { withAdInjection } from '../../../util/algolia/withAdInjections';
import { OrNode } from '../../../../functions/src/types/Hit';
import { TournamentCard } from '../../cards/tournament/TournamentCard';
import { SelectableEventWrapper } from '../../calendar/selectable-event/SelectableEventWrapper';
import { SQUARE_300_AD_INJECTION_CONFIG } from '../../../util/ads/adInjectionConfigs';
import { CALENDAR_AD } from '../../../../functions/src/util/ads/adIds';
import { useRenderHits } from '../../../hooks/algolia/useRenderHits';
import { isKeyedNode } from '../../../util/algolia/renderHits';
import { transformToEventKeyed } from '../../calendar/transformToEventsKeyed';
import { EventHit, RenderCard, RenderWrapper } from './EventsCalendar';

export type EventsVerticalWrapperProps = Omit<
  VerticalCarouselProps,
  'content'
> & {
  hits: OrNode<EventHit<Date>>[];
  transformHits?: (hits: OrNode<EventHit<Date>>[]) => OrNode<EventHit<Date>>[];
  Wrapper?: RenderWrapper<EventHit<Date>, Date>;
  Card?: RenderCard<EventHit<Date>, Date>;
};

const EventsVerticalWrapperUnmemoized = ({
  hits,
  Card = TournamentCard,
  Wrapper = SelectableEventWrapper,
  transformHits,
  ...props
}: EventsVerticalWrapperProps) => {
  const theme = useTheme();

  const renderHit = useMemo(() => {
    return (hit: EventHit<Date>) => {
      // eslint-disable-next-line @blumintinc/blumint/extract-global-constants
      const cardElement = <Card {...hit} />;
      return Wrapper ? <Wrapper hit={hit}>{cardElement}</Wrapper> : cardElement;
    };
  }, [Card, Wrapper]);

  const transform = useCallback(
    (hitsBefore: OrNode<EventHit<Date>>[]) => {
      const hitsTransformed = transformHits
        ? transformHits(hitsBefore)
        : hitsBefore;
      return hitsTransformed
        .map((hitBefore) => {
          if (!isKeyedNode(hitBefore) && 'objectID' in hitBefore) {
            return transformToEventKeyed({ hit: hitBefore, Card, Wrapper });
          }
          return hitBefore;
        })
        .filter(Boolean);
    },
    [Card, Wrapper, transformHits],
  );

  const content = useRenderHits({
    hits,
    render: renderHit,
    transformBefore: transform,
  });

  return (
    <VerticalCarousel
      cardSpacing="16px"
      containerSx={{ background: theme.palette.background.elevation[0] }}
      content={content}
      {...props}
    />
  );
};

EventsVerticalWrapperUnmemoized.displayName = 'EventsVerticalWrapperUnmemoized';

export const EventsVerticalWrapper = memo(EventsVerticalWrapperUnmemoized);
export const EventsVerticalWrapperAds = withAdInjection({
  WrappedComponent: EventsVerticalWrapper,
  ...SQUARE_300_AD_INJECTION_CONFIG,
  id: CALENDAR_AD,
});

export type CalendarExtensionVerticalAdsProps = ComponentProps<
  typeof EventsVerticalWrapperAds
>;
