import { useCallback, FC } from 'react';
import AddIcon from '@mui/icons-material/AddRounded';
import {
  ContainedIconButton,
  ContainedIconButtonProps,
} from '../gradients/ContainedIconButton';
import { memo } from '../../util/memo';
import { useGuardSignIn } from '../../hooks/useGuardSignIn';
import { useAuth } from '../../contexts/AuthContext';
import { useCreateEventWizard } from '../../hooks/organizer/useCreateEventWizard';

export type CreateEventButtonProps = Omit<ContainedIconButtonProps, 'onClick'>;

const CreateEventButtonUnmemoized: FC<CreateEventButtonProps> = (props) => {
  const { uid } = useAuth();
  const { openSignInGuard } = useGuardSignIn();
  const { open: openCreateEventDialog } = useCreateEventWizard();

  const createEvent = useCallback(() => {
    if (!uid) {
      openSignInGuard('Create an Event?');
      return;
    }
    openCreateEventDialog();
  }, [uid, openCreateEventDialog, openSignInGuard]);

  return (
    <ContainedIconButton
      tooltipTitle="Create Event"
      onClick={createEvent}
      {...props}
    >
      <AddIcon />
    </ContainedIconButton>
  );
};

export const CreateEventButton = memo(CreateEventButtonUnmemoized);
