export const IRREGULAR_VERB_KEYS = [
  'start',
  'begin',
  'end',
  'is',
  'are',
  'starts',
  'begins',
  'ends',
] as const;

export type IrregularVerbKey = (typeof IRREGULAR_VERB_KEYS)[number];

export type RelativeTense = {
  past: string;
  future: string;
};

export const IRREGULAR_VERBS: Record<IrregularVerbKey, RelativeTense> = {
  start: { past: 'started', future: 'starts' },
  begin: { past: 'began', future: 'begins' },
  end: { past: 'ended', future: 'ends' },
  is: { past: 'was', future: 'is' },
  are: { past: 'were', future: 'are' },
  starts: { past: 'started', future: 'starts' },
  begins: { past: 'began', future: 'begins' },
  ends: { past: 'ended', future: 'ends' },
} as const;
