/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-lines */
/* eslint-disable @blumintinc/blumint/consistent-callback-naming */
import {
  FC,
  ReactNode,
  useState,
  useCallback,
  useMemo,
  ComponentType,
  useRef,
  useEffect,
} from 'react';
import { Configure, Index, UseConfigureProps } from 'react-instantsearch';
import { useDebounceCallback } from '@react-hook/debounce';
import { prefixIndex } from '../../../functions/src/util/algolia/prefixIndex';
import { Hit, OrNode } from '../../../functions/src/types/Hit';
import { memo, compareDeeply } from '../../util/memo';
import { PreemptStateProvider } from '../../contexts/algolia/PreemptStateContext';
import { dateOnlyMillis } from '../../../functions/src/util/algolia/eventsTransform';
import { useChangedCount } from '../../hooks/useChangedCount';
import { sortedHash } from '../../../functions/src/util/hash/sortedHash';
import { CustomHitsBidirectional } from './CustomHitsBidirectional';
import { PreemptedInstantSearch } from './PreemptedInstantSearch';
import { AlgoliaLayout } from './AlgoliaLayout';
import { CatalogWrapperProps } from './catalog-wrappers/CatalogWrapperProps';
import { EventsVerticalWrapper } from './catalog-wrappers/EventsVerticalWrapper';
import {
  EventHit,
  RenderCard,
  RenderWrapper,
} from './catalog-wrappers/EventsCalendar';

export type Direction = 'forward' | 'backward';

export const PAGE_DEBOUNCE_MS = 300 as const;
/**
 * this is set to 20 instead of 50 because the tournament objects
 * are large and we don't want to load too many at once. TODO: Refactor to
 * 50 once BLU-4200 is complete.
 */
export const EXTENSION_HITS_PER_PAGE = 20 as const;
export const EXTENSION_HITS_INCREMENT = 2 as const;

export type DatedHit = Hit<{ dateDay: number }>;

export type CatalogWrapperBidirectionalProps<THit extends DatedHit> = {
  hits: THit[];
  header?: ReactNode;
  onLoadMore: (direction?: Direction) => void;
  Extension: ComponentType<{ date: Date }>;
  query?: string;
};

export type RenderCatalogWrapperBidirectional = FC<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  CatalogWrapperBidirectionalProps<any>
>;

export type AlgoliaLayoutBidirectionalProps = {
  CatalogWrapperBidirectional: RenderCatalogWrapperBidirectional;
  transformHits?: (hits: OrNode<EventHit<Date>>[]) => OrNode<EventHit<Date>>[];
  transformHitsExtension?: (
    hits: OrNode<EventHit<Date>>[],
  ) => OrNode<EventHit<Date>>[];
  header?: ReactNode;
  configureOptions: Omit<Record<string, UseConfigureProps>, 'query'>;
  Wrapper?: RenderWrapper<EventHit<Date>, Date>;
  Card?: RenderCard<EventHit<Date>, Date>;
};

const clean = (configureOptions: UseConfigureProps) => {
  const { index: __, ...cleaned } = configureOptions;
  return cleaned;
};

const AlgoliaLayoutBidirectionalUnmemoized = ({
  CatalogWrapperBidirectional,
  transformHits,
  transformHitsExtension,
  configureOptions,
  header,
  Wrapper,
  Card,
}: AlgoliaLayoutBidirectionalProps) => {
  const [query, setQuery] = useState<string | undefined>(undefined);

  const indexNames = Object.keys(configureOptions);
  const [forwardIndex, backwardIndex] = indexNames;

  const configureOptionsForward = useMemo(() => {
    return clean(configureOptions[String(forwardIndex)]);
  }, [configureOptions, forwardIndex]);

  const configureOptionsBackward = useMemo(() => {
    return clean(configureOptions[String(backwardIndex)]);
  }, [configureOptions, backwardIndex]);

  const forwardIndexPrefixed = prefixIndex(forwardIndex);
  const backwardIndexPrefixed = prefixIndex(backwardIndex);

  const [pageForward, setPageForward] = useState<(() => void) | null>(null);
  const debouncedPageForward = useDebounceCallback(
    () => {
      pageForward?.();
    },
    PAGE_DEBOUNCE_MS,
    true,
  );

  const [pageBackward, setPageBackward] = useState<(() => void) | null>(null);
  const debouncedPageBackward = useDebounceCallback(
    () => {
      pageBackward?.();
    },
    PAGE_DEBOUNCE_MS,
    true,
  );

  const pageMore = useCallback<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    CatalogWrapperBidirectionalProps<any>['onLoadMore']
  >(
    (direction) => {
      if (direction === 'forward') {
        debouncedPageForward?.();
      } else if (direction === 'backward') {
        debouncedPageBackward?.();
      }
    },
    [debouncedPageForward, debouncedPageBackward],
  );

  const [backwardHits, setBackwardHits] = useState<EventHit<Date>[]>([]);
  const [forwardHits, setForwardHits] = useState<EventHit<Date>[]>([]);

  const combinedHits = useMemo(() => {
    return [...backwardHits, ...forwardHits];
  }, [forwardHits, backwardHits]);

  const combinedHitsTransformed = useMemo(() => {
    return transformHits ? transformHits(combinedHits) : combinedHits;
  }, [combinedHits, transformHits]);

  const loadedHitIdsRef = useRef<string[]>([]);
  useEffect(() => {
    loadedHitIdsRef.current = [
      ...new Set(
        combinedHits.map((hit) => {
          return hit.objectID;
        }),
      ),
    ];
  }, [combinedHits]);

  const CustomHitsForward = useMemo(() => {
    return (
      <CustomHitsBidirectional
        setHits={setForwardHits as any}
        setShowMore={setPageForward}
      />
    );
  }, []);

  const CustomHitsBackward = useMemo(() => {
    return (
      <CustomHitsBidirectional
        setHits={setBackwardHits as any}
        setShowMore={setPageBackward}
      />
    );
  }, []);

  const [catalogWrapper, setCatalogWrapper] = useState<HTMLDivElement | null>(
    null,
  );

  const extensionIntersectionOptions = useMemo(() => {
    return { root: catalogWrapper };
  }, [catalogWrapper]);

  const ExtensionCatalogWrapper = useCallback(
    (props: CatalogWrapperProps<EventHit<Date>>) => {
      return (
        <EventsVerticalWrapper
          Card={Card}
          header={header}
          intersectionOptions={extensionIntersectionOptions}
          transformHits={transformHitsExtension}
          Wrapper={Wrapper}
          {...props}
        />
      );
    },
    [
      Card,
      header,
      Wrapper,
      extensionIntersectionOptions,
      transformHitsExtension,
    ],
  );

  const Extension = useCallback(
    ({ date }) => {
      if (!catalogWrapper) {
        return null;
      }

      const dateDay = dateOnlyMillis(date);
      const today = dateOnlyMillis(new Date());
      const isCurrentOrFuture = dateDay >= today;

      const indexToUse = isCurrentOrFuture ? forwardIndex : backwardIndex;

      const oldConfigureOptions = configureOptions[String(indexToUse)];
      const newHitsPerPage = oldConfigureOptions?.hitsPerPage
        ? oldConfigureOptions.hitsPerPage + EXTENSION_HITS_INCREMENT
        : EXTENSION_HITS_PER_PAGE;

      const updatedConfigureOptions = {
        ...oldConfigureOptions,
        distinct: false,
        numericFilters: [`dateDay = ${dateDay}`],
        hitsPerPage: newHitsPerPage,
      };

      return (
        <AlgoliaLayout
          CatalogWrapper={ExtensionCatalogWrapper}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          configureOptions={updatedConfigureOptions as any}
          index={forwardIndex}
          skipHits={loadedHitIdsRef.current}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      catalogWrapper,
      forwardIndex,
      backwardIndex,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      sortedHash(configureOptions),
      ExtensionCatalogWrapper,
    ],
  );

  const setState = useCallback(
    ({
      uiState,
      setUiState,
    }: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      uiState: any;
      // eslint-disable-next-line no-shadow, @typescript-eslint/no-explicit-any
      setUiState: (uiState: any | ((previousUiState: any) => any)) => void;
    }) => {
      const rootUiState = uiState[''] || {};

      uiState[String(forwardIndexPrefixed)] = {
        ...uiState[String(forwardIndexPrefixed)],
        ...rootUiState,
      };
      uiState[String(backwardIndexPrefixed)] = {
        ...uiState[String(backwardIndexPrefixed)],
        ...rootUiState,
      };

      setQuery(rootUiState.query);

      setUiState(uiState);
    },
    // We have to add configureOptions here in order to trigger
    // PreemptedInstantSearch to change when configureOptions changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [backwardIndexPrefixed, forwardIndexPrefixed, configureOptions],
  );

  // TODO: Eventually remove. This is a workaround because Algolia's
  // responsesCache and requestsCache treats all searches with the same
  // query and index as the same entries; even though the filters and
  // numericFilters of configureOptions can be completely different
  // between those two searches.
  // This solution disables caching as soon as it detects that
  // configureOptions has changed.
  const configureChangedCount = useChangedCount(configureOptions);
  return (
    <PreemptStateProvider>
      <PreemptedInstantSearch
        caching={configureChangedCount === 0}
        onStateChange={setState}
      >
        <Index indexName={forwardIndexPrefixed}>
          <Configure {...configureOptionsForward} query={query} />
          {CustomHitsForward}
        </Index>
        <Index indexName={backwardIndexPrefixed}>
          <Configure {...configureOptionsBackward} query={query} />
          {CustomHitsBackward}
        </Index>
        <div ref={setCatalogWrapper}>
          <CatalogWrapperBidirectional
            Extension={Extension}
            hits={combinedHitsTransformed}
            query={query}
            onLoadMore={pageMore}
          />
        </div>
      </PreemptedInstantSearch>
    </PreemptStateProvider>
  );
};

export const AlgoliaLayoutBidirectional = memo(
  AlgoliaLayoutBidirectionalUnmemoized,
  compareDeeply('configureOptions'),
);
