/* eslint-disable security/detect-object-injection */
import Stack from '@mui/material/Stack';
import type { Timestamp } from 'firebase-admin/firestore';
import { FC, ReactNode, useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  EventsCalendarHeader,
  EventsCalendarHeaderProps,
} from '../headers/EventsCalendarHeader';
import { memo } from '../../../util/memo';
import { DateString, EventKeyed } from '../../../contexts/EventsLazyContext';
import { TournamentCard } from '../../cards/tournament/TournamentCard';
import { CalendarDailyProps } from '../catalogs/CalendarDaily';
import { Tournament } from '../../../../functions/src/types/firestore/Game/Tournament';
import { toDateString } from '../../../../functions/src/util/dates/Dates';
import { ConverterFactory } from '../../../../functions/src/util/firestore/ConverterFactory';
import { SearchAlgolia } from '../search/SearchAlgolia';
import { Hit } from '../../../../functions/src/types/Hit';
import { transformToEvent } from '../../calendar/transformToEventsKeyed';

export type EventHit<TTime = Timestamp> = Tournament<TTime> &
  Hit & {
    dateDay: TTime;
  };
// TODO: Introduce other events types here (like Giveaway)

export type RenderWrapper<
  THit extends EventHit<TTime>,
  TTime = Timestamp,
> = FC<{
  hit: THit;
  children: JSX.Element;
}>;

export type RenderCard<
  THit extends EventHit<TTime>,
  TTime = Timestamp,
> = FC<THit>;

export type EventsCalendarProps<THit extends EventHit | EventKeyed> =
  EventsCalendarHeaderProps & {
    CalendarDaily: FC<
      Pick<
        CalendarDailyProps,
        'dayToEvents' | 'onCalendarEndReached' | 'Extension' | 'query'
      >
    >;
    hits: THit[];
    onLoadMore: (direction?: 'forward' | 'backward') => void;
    Extension: CalendarDailyProps['Extension'];
    Wrapper?: RenderWrapper<EventHit<Date>, Date>;
    Card?: RenderCard<EventHit<Date>, Date>;
    query?: string;
  };

export const converter = ConverterFactory.buildDateConverter<EventHit<Date>>();

const EventsCalendarUnmemoized = <THit extends EventHit | EventKeyed>({
  CalendarDaily,
  hits,
  onLoadMore,
  Wrapper,
  Extension,
  query,
  Title,
  Card = TournamentCard,
  ...headerProps
}: EventsCalendarProps<THit>) => {
  const theme = useTheme();

  const dayToEvents = useMemo(() => {
    return hits.reduce((prev, curr, index) => {
      if (!!curr && 'objectID' in curr) {
        const hitConverted = converter.convertData(curr);
        const dateKey = toDateString(new Date(hitConverted.date));

        if (!prev[String(dateKey)]) {
          prev[String(dateKey)] = [];
        }

        const eventKeyed = transformToEvent({
          hit: hitConverted,
          Card,
          Wrapper,
        });
        prev[String(dateKey)].push(eventKeyed);
      } else if (!!curr && 'Node' in curr && 'dateDay' in curr) {
        const referenceHitUnconverted =
          hits
            .slice(0, index)
            .reverse()
            .find((prevHit) => {
              return 'date' in prevHit;
            }) ||
          hits.slice(index + 1).find((nextHit) => {
            return 'date' in nextHit;
          });
        const referenceHit = referenceHitUnconverted
          ? converter.convertData(referenceHitUnconverted)
          : undefined;

        const referenceDate = referenceHit?.date || curr.dateDay;

        const dateKey = toDateString(new Date(referenceDate));
        if (!prev[String(dateKey)]) {
          prev[String(dateKey)] = [];
        }

        const eventKeyed: EventKeyed = {
          Node: curr.Node as ReactNode,
          key: curr.key,
          dateDay: curr.dateDay,
        };
        prev[String(dateKey)].push(eventKeyed);
      }

      return prev;
    }, {} as Record<DateString, EventKeyed[]>);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hits, Card, Wrapper]);

  const Search = useMemo(() => {
    return <SearchAlgolia collapsable={false} placeholder="Search events..." />;
  }, []);

  const calendarContent = useMemo(() => {
    return (
      <Stack
        sx={{
          mx: 1,
          [theme.breakpoints.down(375)]: {
            mx: 0,
          },
          mb: 1,
          mt: 2,
          borderRadius: '5px',
          overflow: 'hidden',
        }}
      >
        <CalendarDaily
          dayToEvents={dayToEvents}
          Extension={Extension}
          query={query}
          onCalendarEndReached={onLoadMore}
        />
      </Stack>
    );
  }, [
    theme.breakpoints,
    CalendarDaily,
    dayToEvents,
    onLoadMore,
    Extension,
    query,
  ]);

  const header = useMemo(() => {
    return (
      <EventsCalendarHeader Search={Search} Title={Title} {...headerProps}>
        {calendarContent}
      </EventsCalendarHeader>
    );
  }, [Title, Search, headerProps, calendarContent]);

  return header;
};

export const EventsCalendar = memo(EventsCalendarUnmemoized);
