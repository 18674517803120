/* eslint-disable max-lines */
import {
  useMemo,
  useState,
  useEffect,
  useCallback,
  ReactNode,
  ComponentType,
} from 'react';
import Box from '@mui/material/Box';
import { Required } from 'utility-types';
import Stack from '@mui/material/Stack';
import Fade from '@mui/material/Fade';
import { memo } from '../../../util/memo';
import { UniversalAppStatus } from '../../error/UniversalAppStatus';
import { useNumVisibleColumns } from '../../../hooks/calendar/useNumVisibleColumns';
import { DateString, EventKeyed } from '../../../contexts/EventsLazyContext';
import { ReactGlider, ReactGliderProps } from '../../ReactGlider';
import { toLocalMidnightDate } from '../../../../functions/src/util/date/paramsUtc';
import { CalendarDayTodaylight } from './CalendarDayTodaylight';
import { CALENDAR_DAY_WIDTH } from './CalendarDay';
import { assertSafe } from 'functions/src/util/assertSafe';

export const CALENDAR_COLUMN_WIDTH = CALENDAR_DAY_WIDTH + 8;

export type GliderCalendarProps = Omit<
  Required<ReactGliderProps, 'gliderRef'>,
  'hasArrows' | 'duration' | 'itemWidth' | 'children' | 'cursor'
> & {
  dayToEvents: Record<DateString, EventKeyed[]>;
  height: string;
  initialDate?: Date;
  placeholderText?: string;
  Extension: ComponentType<{ date: Date }>;
  query?: string;
};

export const GliderCalendarUnmemoized: React.FC<GliderCalendarProps> = ({
  dayToEvents,
  height,
  initialDate,
  gliderRef,
  placeholderText,
  Extension,
  query,
  ...props
}) => {
  const { firstEventRef } = useNumVisibleColumns();

  const sortedDayToEvents = useMemo(() => {
    // eslint-disable-next-line no-restricted-properties
    return Object.entries(dayToEvents).sort(([dateA], [dateB]) => {
      return new Date(dateA).getTime() - new Date(dateB).getTime();
    });
  }, [dayToEvents]);

  const [prevDayToEvents, setPrevDayToEvents] = useState(sortedDayToEvents);

  useEffect(() => {
    const prevDays = prevDayToEvents.map(([date, _]) => {
      return date;
    });
    const currentDays = sortedDayToEvents.map(([date, _]) => {
      return date;
    });

    if (prevDays[0] !== currentDays[0]) {
      const newDaysCount = currentDays.indexOf(prevDays[0]);
      if (newDaysCount > 0) {
        gliderRef.current?.shiftCursor(newDaysCount);
      }
    }

    setPrevDayToEvents(sortedDayToEvents);
  }, [gliderRef, prevDayToEvents, sortedDayToEvents]);

  const findIndexOf = useCallback(
    (targetDate: Date) => {
      const targetTime = targetDate.getTime();

      return sortedDayToEvents
        .map(([dateString], index) => {
          const date = new Date(dateString);
          const diff = Math.abs(date.getTime() - targetTime);

          return { diff, index } as const;
        })
        .reduce(
          (min, curr) => {
            return curr.diff < min.diff ? curr : min;
          },
          {
            diff: Infinity,
            index: -1,
          },
        ).index;
    },
    [sortedDayToEvents],
  );

  const cursorInitial = useMemo(() => {
    if (sortedDayToEvents.length === 0) {
      return 'no-events';
    }
    return initialDate ? findIndexOf(initialDate) : 0;
  }, [initialDate, sortedDayToEvents.length, findIndexOf]);

  const [extensionCache, setExtensionCache] = useState<
    // eslint-disable-next-line @blumintinc/blumint/enforce-boolean-naming-prefixes
    Record<string, { node: ReactNode; intersected: boolean }>
  >({});

  // eslint-disable-next-line @blumintinc/blumint/prefer-usememo-over-useeffect-usestate
  useEffect(() => {
    setExtensionCache({});
    gliderRef.current?.setCursor(initialDate ? findIndexOf(initialDate) : 0);
    // We want to reset setExtensionCache as little as possible: only when query
    // changes. So we avoid including initialDate or gliderRef in the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const cacheExtension = useCallback(
    (dateStringified: string) => {
      setExtensionCache((prevCache) => {
        if (
          !prevCache[assertSafe(dateStringified)] ||
          !prevCache[assertSafe(dateStringified)].intersected
        ) {
          const localMidnight = toLocalMidnightDate(dateStringified);

          const extension = <Extension date={localMidnight} />;
          return {
            ...prevCache,
            // eslint-disable-next-line @blumintinc/blumint/enforce-boolean-naming-prefixes
            [dateStringified]: { node: extension, intersected: true },
          };
        }
        return prevCache;
      });
    },
    //extensionCache is here to ensure the Extension gets reset when the search query changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Extension, extensionCache],
  );

  const Columns = useMemo(() => {
    return sortedDayToEvents.map(([dateString, eventsOfDate], index) => {
      const extensionData = extensionCache[assertSafe(dateString)] || {
        node: undefined,
        // eslint-disable-next-line @blumintinc/blumint/enforce-boolean-naming-prefixes
        intersected: false,
      };

      return (
        <Box key={dateString} ref={index === 0 ? firstEventRef : undefined}>
          <CalendarDayTodaylight
            dateString={dateString}
            eventsOfDate={eventsOfDate}
            extension={extensionData.node}
            height={height}
            intersected={extensionData.intersected}
            onIntersect={cacheExtension}
          />
        </Box>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extensionCache, firstEventRef, sortedDayToEvents]);

  const glider = useMemo(() => {
    return (
      cursorInitial !== 'no-events' && (
        <ReactGlider
          cursor={cursorInitial}
          duration={0}
          gliderRef={gliderRef}
          hasArrows={false}
          itemWidth={CALENDAR_COLUMN_WIDTH}
          {...props}
        >
          {Columns}
        </ReactGlider>
      )
    );
  }, [Columns, cursorInitial, gliderRef, props]);

  return (
    <Stack height={height}>
      {!!placeholderText && cursorInitial === 'no-events' && (
        <Fade easing="ease-in-out" in>
          <div>
            <UniversalAppStatus
              description={placeholderText}
              imgUrl="/assets/images/mascots/mascot-crying.png"
              prefetchImage
              showButton={false}
              size={300}
              subText={false}
              title="NO EVENTS FOUND"
            />
          </div>
        </Fade>
      )}
      {glider}
    </Stack>
  );
};

export const GliderCalendar = memo(GliderCalendarUnmemoized);
