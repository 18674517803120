import { FC, useCallback, useMemo } from 'react';
import Box from '@mui/material/Box';
import {
  EventHit,
  RenderWrapper,
} from '../../algolia/catalog-wrappers/EventsCalendar';
import { useCreateEventContext } from '../../../contexts/organizer/create-event/CreateEventContext';
import { memo } from '../../../util/memo';
import { AlgoliaEventsCalendar } from '../../algolia/AlgoliaEventsCalendar';
import { Selectable } from '../../Selectable';
import { NewTabTournamentCard } from '../../cards/tournament/NewTabTournamentCard';
import { useCreateTournament } from '../../../hooks/organizer/useCreateTournament';
import { EVENT_PUBLISHED } from '../../../../functions/src/util/algolia/config/events';
import { EventFlowBase } from './EventFlowBase';

export const EVENT_CALENDAR_TITLE = 'Events Calendar' as const;

export const CopyEventCalendar: FC = memo(function CopyEventUnmemoized() {
  const {
    eventCreationSettings: { eventType },
    event,
    setEvent,
  } = useCreateEventContext();
  const { createTournament } = useCreateTournament();
  const { id: tournamentId, title, gameTitle, gameId, date } = event || {};
  const someEventSelected = useMemo(() => {
    return !!tournamentId;
  }, [tournamentId]);

  const buttonMessage = useMemo(() => {
    if (!someEventSelected) {
      return 'Copy Tournament';
    }
    return `Copy Tournament: ${title} (${gameTitle})`;
  }, [title, someEventSelected, gameTitle]);

  const SelectableWrapper = useCallback<RenderWrapper<EventHit<Date>, Date>>(
    ({ hit, children }) => {
      const { id } = hit;
      return (
        <Selectable
          borderRadius="10px"
          isSelected={id === tournamentId}
          value={id}
          // eslint-disable-next-line @blumintinc/blumint/enforce-callback-memo
          onChange={(_, isSelected) => {
            if (isSelected) {
              setEvent(hit);
            }
          }}
        >
          {children}
        </Selectable>
      );
    },
    [setEvent, tournamentId],
  );

  const copySelectedTournament = useCallback(async () => {
    if (!gameId || !tournamentId) {
      return;
    }
    await createTournament(gameId, tournamentId);
  }, [createTournament, gameId, tournamentId]);

  const configureOptions = useMemo(() => {
    return {
      filters: EVENT_PUBLISHED,
    };
  }, []);

  const continueButtonProps = useMemo(() => {
    return {
      isAsync: true,
      children: buttonMessage,
      onClick: copySelectedTournament,
      disabled: !someEventSelected,
    };
  }, [buttonMessage, copySelectedTournament, someEventSelected]);

  return (
    <EventFlowBase
      Content={
        <Box sx={{ width: '100%' }}>
          <AlgoliaEventsCalendar
            Card={NewTabTournamentCard}
            configureOptions={configureOptions}
            height="520px"
            initialDate={date}
            Title={EVENT_CALENDAR_TITLE}
            Wrapper={SelectableWrapper}
          />
        </Box>
      }
      continueButtonProps={continueButtonProps}
      subtitle={`Select a ${eventType.toLowerCase()} to copy.`}
      title={`Copy ${eventType}`}
    />
  );
});
