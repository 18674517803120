import Stack from '@mui/material/Stack';
import { useContext } from 'react';
import { memo } from '../../../util/memo';
import {
  SPONSORS_WIDTH,
  TournamentCardSponsors,
} from './TournamentCardSponsors';
import { TournamentCardThumbnail } from './TournamentCardThumbnail';
import { TournamentContext } from 'src/contexts/docs/TournamentContext';

export const IMG_URL_DEFAULT =
  '/assets/images/tournament-calendar/img-url-default.png';
export const SPONSOR_IMG_URL_DEFAULT = '/assets/images/logo.svg';

export const IMAGERY_HEIGHT = 106 as const;
export const IMAGERY_WIDTH = 292 as const;

const GAP = 4 as const;

const TournamentCardImageryUnmemoized = () => {
  const {
    tournamentThumbnailImgUrl = IMG_URL_DEFAULT,
    streamerLiveThumbnail,
    primaryPlaybackId,
    sponsors,
  } = useContext(TournamentContext);

  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: 'space-evenly',
        alignItems: 'center',
        gap: `${GAP}px`,
        display: 'flex',
        height: `${IMAGERY_HEIGHT}px`,
      }}
    >
      <TournamentCardThumbnail
        height={IMAGERY_HEIGHT}
        imgUrl={streamerLiveThumbnail || tournamentThumbnailImgUrl}
        primaryPlaybackId={primaryPlaybackId}
        width={
          sponsors?.length
            ? IMAGERY_WIDTH - SPONSORS_WIDTH - GAP
            : IMAGERY_WIDTH
        }
      />
      {!!sponsors && sponsors.length > 0 && (
        <TournamentCardSponsors height={IMAGERY_HEIGHT} sponsors={sponsors} />
      )}
    </Stack>
  );
};

export const TournamentCardImagery = memo(TournamentCardImageryUnmemoized);
