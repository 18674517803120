import { useContext } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import PersonIcon from '@mui/icons-material/PersonRounded';
import PeopleIcon from '@mui/icons-material/PeopleRounded';
import Tooltip from '@mui/material/Tooltip';
import { memo } from '../../../util/memo';
import { ELLIPSIS_SX } from '../../../util/string';
import { TournamentPayout } from './TournamentPayout';
import { TournamentContext } from 'src/contexts/docs/TournamentContext';
import { GradientIcon } from 'src/components/gradients/GradientIcon';
import { GradientTypography } from 'src/components/gradients/GradientTypography';

const TournamentCardInfoUnmemoized = () => {
  const {
    title,
    gameTitle,
    participantTotal,
    maxTeamCount,
    maxTeamSize,
    guestlistTotal,
  } = useContext(TournamentContext);

  const maxCompetitorCount = maxTeamSize * maxTeamCount;
  const currentCompetitorCount =
    participantTotal > 0 ? participantTotal : guestlistTotal;

  const { teamSizeIcon, tooltipTitle } =
    maxTeamSize === 1
      ? {
          teamSizeIcon: PersonIcon,
          tooltipTitle: 'Solo players only',
        }
      : {
          teamSizeIcon: PeopleIcon,
          tooltipTitle: `Teams of ${maxTeamSize}`,
        };

  return (
    <Stack spacing={-1} sx={{ pl: '2px' }}>
      <Stack direction="row" justifyContent="space-between">
        <Typography
          color="text.primary"
          sx={{
            flexGrow: 1,
            ...ELLIPSIS_SX,
          }}
          variant="subtitle1"
        >
          {title}
        </Typography>
        <TournamentPayout sx={{ fontWeight: 700 }} variant="body1" />
      </Stack>
      <Stack direction="row" justifyContent="space-between" spacing={1}>
        <GradientTypography
          sx={{
            fontWeight: 450,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
          variant="subtitle1"
        >
          {gameTitle}
        </GradientTypography>
        <Stack alignItems="center" direction="row" spacing={1}>
          <GradientTypography
            gradientColor="primary.vertical"
            variant="subtitle1"
          >
            {`${currentCompetitorCount}/${maxCompetitorCount}`}
          </GradientTypography>
          <Tooltip title={tooltipTitle}>
            <Stack>
              <GradientIcon
                gradientColor="primary.horizontal"
                IconComponent={teamSizeIcon}
                sx={{ width: 20, height: 20 }}
              />
            </Stack>
          </Tooltip>
        </Stack>
      </Stack>
    </Stack>
  );
};

export const TournamentCardInfo = memo(TournamentCardInfoUnmemoized);
