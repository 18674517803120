import { ChangeEventHandler, useCallback, useState } from 'react';
import { memo } from '../../../util/memo';
import { Game } from '../../../../functions/src/types/firestore/Game';
import {
  SearchDropdown,
  SearchDropdownProps,
} from '../catalogs/SearchDropdown';
import { TitleCard } from '../../cards/TitleCard';
import { Hit, OrNode } from '../../../../functions/src/types/Hit';
import { useRenderHits } from '../../../hooks/algolia/useRenderHits';

export type GameDropdownSearchProps = Omit<SearchDropdownProps, 'content'> & {
  hits: OrNode<Hit<Game<Date>>>[];
  onGameSelect: (gameHit: Hit<Game<Date>> | undefined) => void;
};

const GameDropdownSearchUnmemoized = ({
  hits,
  onGameSelect,
  ...props
}: GameDropdownSearchProps) => {
  const [selectedTitle, setSelectedTitle] = useState<string | undefined>(
    undefined,
  );

  const selectGame = useCallback(
    (gameHit: Hit<Game<Date>>) => {
      setSelectedTitle(gameHit.username);
      onGameSelect(gameHit); // May be redundant
    },
    [onGameSelect, setSelectedTitle],
  );

  const renderHits = useCallback(
    (hit: Hit<Game<Date>>) => {
      const { username } = hit;
      return (
        <TitleCard
          title={username}
          // eslint-disable-next-line @blumintinc/blumint/enforce-callback-memo
          onClick={() => {
            return selectGame(hit);
          }}
        />
      );
    },
    [selectGame],
  );

  const games = useRenderHits({
    hits,
    render: renderHits,
  });

  const changeSelectedGame = useCallback<
    ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  >(
    (e) => {
      const selectedGame = hits.find((hit) => {
        return 'username' in hit && hit.username === e.target.value;
      }) as Hit<Game<Date>> | undefined;
      if (!selectedGame) {
        return;
      }
      onGameSelect(selectedGame);
    },
    [hits, onGameSelect],
  );

  return (
    <SearchDropdown
      content={games}
      placeholder={'Search for a game...'}
      value={selectedTitle}
      onChange={changeSelectedGame}
      {...props}
    />
  );
};

export const GameDropdownSearch = memo(GameDropdownSearchUnmemoized);
